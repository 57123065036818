import { useEffect, useState } from 'react';

interface RXTrackingPixelProps {
  seoPageId: number;
}

export function getRXTrackingUrl(seoPageId: number) {
  const tracingURL = '/track/clickAccessLog.do';
  const { referrer } = document;
  const url = new URL(window.location.href);

  let trackUrl = tracingURL;
  trackUrl += url.search ? `${url.search}&` : '?';
  trackUrl += `t_seoPageId=${seoPageId}`;
  if (referrer) {
    trackUrl += `&t_referer=${referrer}`;
  }
  trackUrl += `&t_url=${url.pathname}`;

  return trackUrl;
}

function RXTrackingPixel({ seoPageId }: RXTrackingPixelProps) {
  const [RXTrackingURL, setRXTrackingURL] = useState<string>();

  useEffect(() => {
    setRXTrackingURL(getRXTrackingUrl(seoPageId));
  }, [seoPageId]);

  if (!RXTrackingURL) {
    return null;
  }

  // eslint-disable-next-line @next/next/no-img-element
  return <img src={RXTrackingURL} width={1} height={1} alt="" style={{ display: 'none' }} />;
}

export default RXTrackingPixel;
